import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link } from "react-router-dom";

class Login extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
    }

    submit(e) {
        e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/auth/login', { ...this.state.info }, j => {
            if (j.item)
                this.props.navigate('/');
            else if (j.errorCode)
                this.alert(j.errorMessage);
            this.setState({ submitting: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ submitting: false });
        });
    }

    render() {
        return <div className="container">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-4">
                    <Card title="Login">
                        <form onSubmit={this.submit}>
                            <FormInput model={this.state.info} name="email" label="Email" type="text" required />
                            <FormInput model={this.state.info} name="password" label="Password" type="password" required />
                            <div className="d-grid gap-2">
                                <button type="submit" className="btn btn-primary btn-lg" disabled={this.state.submitting}>Login</button>
                            </div>
                        </form>
                        <div className="py-3 text-center">
                            <Link to="/forgot-password">Forgot Password</Link>
                        </div>
                    </Card>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(Login);