import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import Tab from 'react-bootstrap/Tab';
import Icon from "./Icon";
import { Pop } from "./PopModal";

class SitePages extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: true
        };
        this.load = this.load.bind(this);
        this.deletePage = this.deletePage.bind(this);
    }

    componentWillMount() {
        this.setState({ loading: true });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tab !== this.state.tab)
            this.load();
    }

    deletePage(page) {
        Pop.showConfirm(<>Are you sure you want to delete page <strong>{page.name}</strong>?</>, 'Delete Page', () => {
            qreq.post('/api/editor/page/delete', { id: page.id }, j => {
                if(j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Page has been deleted.');
                    this.load();
                    if(this.props.params.id && Number(this.props.params.id) === page.id)
                        this.props.navigate(this.props.template ? '/editor/template/' + (this.props.params.templateID ?? 'new') : '/editor/site/' + (this.props.params.templateID ?? 'new'));
                }
            }, this.unkownErrorCallback);
        })
    }

    load() {
        qreq.get('/api/editor/page/list?siteDomainID=' + (this.props.params.templateID ?? 0), j => {
            if (j.list)
                this.setState({ list: j.list });
            this.setState({ loading: false });
        }, () => {
            this.setState({ loading: false });
        });
    }


    render() {
        return <>
            <div className="mb-3">
                <Link to={this.props.params.templateID ? this.props.template ? '/editor/template/' + this.props.params.templateID + '/new' : '/editor/site/' + this.props.params.templateID + '/new' : '/editor/page/new'} className="btn btn-primary"><Icon icon="faPlus" /> New Page</Link>
            </div>
            <ul className="list-group rounded-0">
                {this.state.list.map(obj =>

                    <li key={obj.id} className={'list-group-item p-2' + (Number(this.props.params.id) === obj.id ? ' list-group-item-primary' : '')}>
                        <div className="d-flex">
                            <Link to={(this.props.params.templateID ? this.props.template ? '/editor/template/' + this.props.params.templateID + '/page/' : '/editor/site/' + this.props.params.templateID + '/page/' : '/editor/page/') + obj.id} className="d-block pagesSelect flex-fill"><FontAwesomeIcon icon={fa.faPencil} /> {obj.name}</Link>
                            <button type="button" className="btn btn-default d-block" onClick={() => this.deletePage(obj)}><Icon icon="faTrash" /></button>
                        </div>
                    </li>
                )}
            </ul>
            {this.state.loading ? <LoadingAlert /> : ''}

        </>;
    }
}

export default withParamsAndNavigate(SitePages);