import React from "react";
import BaseCom from "./BaseCom";
import { v4 as uuidv4 } from 'uuid';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import Globals from "../shared/Globals";
import { AIModalGlobal } from "./AIModal";
import { HTMLEditorLinkGlobal } from "./HTMLEditorLink";

class HTMLEditor extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            id: 't' + uuidv4().replace(/-/g, ''),
            value: this.props.defaultValue ?? this.props.value
        };
    }

    componentDidMount() {
        var ele = document.getElementById(this.state.id);
        if (ele) {
            var tox = ele.parentNode.getElementsByClassName('tox');
            if (tox.length === 0) {
                tinymce.init({
                    selector: 'textarea#' + this.state.id,
                    statusbar: false,
                    promotion: false,
                    menubar: false,
                    toolbar: 'ai customLink | bold italic underline | styles fontfamily fontsize | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                    setup: ed => {
                        ed.on('change', e => {
                            this.setState({ value: ed.getContent() });
                            if (this.props.onChange)
                                this.props.onChange(ed.getContent());
                        });
                        ed.ui.registry.addButton('AI', {
                            icon: 'AI',
                            onAction: () => {
                                AIModalGlobal.show(null, v => {
                                    ed.insertContent(v);
                                }, !Globals.get('isTemplate'));
                            }
                        });
                        ed.ui.registry.addButton('customLink', {
                            icon: 'link',
                            onAction: () => {
                                var url = null;
                                var preSel = ed.selection.getContent({ format: 'html' });
                                var match = preSel.match(/<a href="([^"]+)">([^<]+)<\/a>/);
                                if(match) {
                                    if(match.length >= 2)
                                        url = match[1];
                                }
                                HTMLEditorLinkGlobal.show(url, v => {
                                    var sel = ed.selection.getContent({ format: 'text' });
                                    if(!v)
                                        ed.insertContent(sel);
                                    else
                                        ed.insertContent('<a href="' + v + '"">' + sel+ '</a>')
                                });
                            }
                        });
                    }
                });
            }
            else {
                ele.style.display = 'none';
            }
        }
    }

    componentWillUnmount() {
        tinymce.get(this.state.id).remove();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.defaultValue !== this.props.defaultValue)
            this.setState({ value: this.props.defaultValue });
        if (prevProps.value !== this.props.value)
            this.setState({ value: this.props.value });
    }

    render() {
        return <div id={this.state.id + '-wrapper'}>
            <textarea id={this.state.id} defaultValue={this.props.defaultValue ?? this.props.value}></textarea>
        </div>;
    }
}

export default HTMLEditor;