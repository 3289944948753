import React from 'react';
import { Navigate } from 'react-router-dom';
import Globals from '../shared/Globals';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html';

const allowedHTML = {
    parseStyleAttributes: false,
    allowedTags: ['table', 'thead', 'tbody', 'tr', 'td', 'th', 'div', 'span', 'b', 'i', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'hr', 'img', 'br', 'p', 'ul', 'ol', 'li'],
    allowedAttributes: {
        table: ['style', 'class'],
        b: ['style', 'class'],
        i: ['style', 'class'],
        strong: ['style', 'class'],
        div: ['style', 'class', 'varnote'],
        span: ['style', 'class'],
        tr: ['style', 'class'],
        td: ['style', 'class', 'colspan'],
        th: ['style', 'class', 'colspan'],
        a: ['href', 'target'],
        img: ['src', 'alt', 'style', 'class']
    },
    allowedSchemes: ['data', 'http', 'https']
};

export default class BaseCom extends React.Component {

    constructor(props, state) {
        if (!props)
            props = {};
        super(props);
        if (!state)
            state = {};
        this.state = state;
        this.set = this.set.bind(this);
        this.get = this.get.bind(this);
        this.goBack = this.goBack.bind(this);
        this.alert = this.alert.bind(this);
        this.requireLogin = this.requireLogin.bind(this);
        this.wrapModal = this.wrapModal.bind(this);
    }

    set(a, b) {
        var state = this.state;
        state[a] = b;
        this.setState(state);
    }

    get(a) {
        return this.state[a];
    }

    goBack() {
        window.history.go(-1);
    }

    success(msg) {
        this.toast().success(msg);
    }

    alert(msg) {
        this.toast().error(msg);
    }
    
    warn(msg) {
        this.toast().warn(msg);
    }

    showError(msg) {
        this.toast().error(msg);
    }

    navigate(loc) {
        return (<Navigate to={loc} />);
    }

    requireLogin(section, callback) {

        if (callback) {
            callback(Globals.get('user'));
        }

        return Globals.get('user') ? true : false;
    }

    wrapModal(children, title, size) {
        return <Modal show={this.props.show} onHide={() => { if (this.props.onHide) this.props.onHide(); }} size={size}>
            {title ?
                <Modal.Header closeButton={this.props.onHide ? true : false}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header> : ''}
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>;
    }

    toast() {
        return toast;
    }

    unkownErrorCallback() {
        toast.error('Unknown error ocurred. Please try again.');
    }

    processHTML(html, allowLinks) {
        if(allowLinks)
        {
            var allowed = {...allowedHTML, allowedTags: [...allowedHTML.allowedTags, 'a']};
            return sanitizeHtml(html, allowed);        
        }
        else
            return sanitizeHtml(html, allowedHTML);
    }
}

