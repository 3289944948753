import React from 'react';
import BaseCom from './BaseCom';
import LoadingAlert from './LoadingAlert';
import { qreq } from '../shared/qrequest';
import DataTable from './DataTable';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import Alert from './Alert';
import AdminLayout from './AdminLayout';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { Pop } from './PopModal';
import Card from './Card';

class AdminOrganization extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            user: null,
            list: [],
            loading: true,
            loaded: false
        };
        this.listRef = React.createRef();
        this.load = this.load.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/settings/editor', j => {
            if(j.item)
                this.setState({ settings: j.item });
        });
        this.load();
        document.addEventListener('scroll', this.scrollHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollHandler);
    }

    load() {
        qreq.get('/api/admin/organization/list', j => {
            if(j.list) {
                this.setState({ list: j.list, loaded: true, loading: false });
                if(j.list.length > 0)
                    this.scrollHandler();
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    scrollHandler() {
        if (!this.listRef.current)
            return;
        const bottom = this.listRef.current.getBoundingClientRect().bottom - 50 <= window.innerHeight;
        if (bottom) {
            if (this.state.loading || this.state.endOfList)
                return;
            this.setState({ loading: true });
            qreq.get('/api/admin/organization/list?page=' + (this.state.page+1), j => {
                if (j.list) {
                    if (j.list.length === 0)
                        this.setState({ endOfList: true });
                    else {
                        var list = [...this.state.list];
                        list = list.concat(j.list);
                        this.setState({ list: list, page: this.state.page + 1 });
                    }
                }
                else
                    this.alert(j.errorMessage);
                this.setState({ loading: false });

            }, () => {
                this.setState({ loading: false });
                this.unkownErrorCallback();
            });
        }
    }

    preview(site) {
        if(this.state.settings.renderUrl)
            window.open(this.state.settings.renderUrl.replace('*', site.masterID).replace('%', ''));
        else
            window.open('https://' + site.masterID + '.' + window.location.host + '/');
    }

    render() {

        if (!this.state.loaded)
            return (<div className="container-fluid"><LoadingAlert /></div>);

        if (this.state.errorMessage)
            return <Alert type="danger" icon="ExclamationTriangle" message={this.state.errorMessage} />


        return <AdminLayout>
            <div className="container-fluid">
                <Card title="All Organization" headerButtons={<><Link to="/admin/organizations/new" type="button" className="btn btn-primary"><Icon icon="faPlus" /> New Organization</Link></>}>
                    <DataTable tableRef={this.listRef} columns={[
                        { icon: 'Pencil', link: '/admin/organizations/edit/:id', tooltip: 'Edit Organization' },
                        { icon: 'Trash', tooltip: 'Delete Site', onClick: row => {
                            Pop.showConfirm('Are you sure you want to delete \'' + row.domainName + '\'?', 'Delete Site', () => {
                                qreq.post('/api/admin/organization/delete', {...row}, j => {
                                    if(j.errorCode === 0)
                                        this.load();
                                    else
                                        this.alert(j.errorMessage);
                                });
                            });
                        } },
                        { name: 'id', title: 'ID', link: '/admin/organizations/edit/:id', tooltip: 'Edit Organization', className: 'd-none d-md-table-cell' },
                        { name: 'name', title: 'Domain', link: '/admin/organizations/edit/:id', tooltip: 'Edit Organization' },
                        { name: 'createdDate', title: 'Created', format: 'datetime', className: 'd-none d-md-table-cell' }
                    ]} data={this.state.list} loading={this.state.loading} />
                </Card>
            </div>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminOrganization);