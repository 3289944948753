import Auth from "../shared/Auth";
import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import Icon from "./Icon";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import FormInput from "./FormInput";
import HTMLEditor from "./HTMLEditor";
import Globals from "../shared/Globals";

class AdminTemplates extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            list: [],
            info: {}
        };
        this.create = this.create.bind(this);
        this.edit = this.edit.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            if (!u || !Auth.isAdmin())
                this.props.navigate('/login');
            else {
                qreq.get('/api/template/list', j => {
                    if (j.list)
                        this.setState({ list: j.list, loaded: true });
                    else
                        this.alert(j.errorMessage);
                }, this.unkownErrorCallback);
            }
        });
    }

    create(e) {
        if(e) e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/template/create', {...this.state.info}, j => {
            if(j.item)
                this.props.navigate('/editor/template/' + j.item.id);
            else
                this.alert(j.errorMessage);
            this.setState({ submitting: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ submitting: false });
        });
    }

    edit(item) {
        this.props.navigate('/admin/templates/edit/' + item.id);
    }

    render() {
        return <AdminLayout>
            <Modal show={this.state.showNew} onHide={() => this.setState({ showNew: false })}>
                <form onSubmit={this.create}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormInput model={this.state.info} name="templateName" label="Template Name" type="text" required />
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-success" disabled={this.state.submitting}>
                            Create
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showNew: false })} disabled={this.state.submitting}>
                            Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Card title="Templates" headerButtons={<><button type="button" onClick={() => this.setState({ showNew: true })} className="btn btn-primary"><Icon icon="faPlus" /> New Template</button></>}>
                {this.state.loaded ? <>
                    <div className="row">
                        {this.state.list.map(i => <div key={i.id} className="col-6 col-lg-3 col-xl-2 text-center p-3">
                            <Link to={'/editor/template/' + i.id}>
                                <img src={Globals.imageUrl('/api/media/site/preview/' + i.masterID)} alt="" className="img-fluid" />
                                <div><span role="button" title="Edit" onClick={e => { e.preventDefault(); this.edit(i) }}><Icon icon="faPencil" /></span> {i.templateName}</div>
                            </Link>
                        </div>)}
                    </div>
                </> : <LoadingAlert />}
            </Card>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminTemplates);