import BaseCom from "./BaseCom";
import { Link } from "react-router-dom";

export default class Breadcrumb extends BaseCom {

    render() {
        return <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {this.props.path.map(obj => <li key={obj[0]} className={'breadcrumb-item' + (this.props.path.indexOf(obj) === this.props.path.length ? ' active' : '')}>
                    {obj.length > 1 ? <Link to={obj[1]}>{obj[0]}</Link> : <span>{obj[0]}</span>}
                </li>)}
            </ol>
        </nav>
    }
}