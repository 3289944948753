/*
    Deprecated
    This component is deprecated and not used anymore.
    New component: DomainSearch.js
*/
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link } from "react-router-dom";
import { qreq } from "../shared/qrequest";
import Auth from "../shared/Auth";
import Icon from "./Icon";
import EditorHeader from "./EditorHeader";

class SelectDomain extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            item: {}
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            if (!u)
                this.props.navigate('/login');
        });
        // deprecated page, exit
        window.location.replace('/');        
    }

    onSubmit(e) {
        e.preventDefault();
        qreq.post('/api/editor/domain/select', this.state.item, j => {
            if (j.errorCode === 0)
                this.props.navigate('/editor', { replace: true });
            else
                this.alert(j.errorMessage);
        });
    }

    render() {
        return <div className="container pt-3">
            <div className="card card-shadow2">
                <div className="container-fluid">
                    <h3 className="mt-4">
                        Claim Your Domain Name
                    </h3>
                    <br />
                    <div className="input-group mb-3">
                        <FormInput model={this.state.item} type="text" className="form-control" label="mycooldomain" labelAsPlaceholder postInput={<button className="btn btn-success" type="submit">
                            &nbsp; <Icon icon="faSearch" className="fa-lg" /> &nbsp;
                        </button>} />
                    </div>
                    <br />
                    <div className="row">
                        {this.state.list.map(i => <div key={i.id} className="col-lg-6 col-12">
                            <div className="row">
                                <div className="col-6">
                                    <span className="fs-3">taplocal<b>.net</b></span>
                                </div>
                                <div className="col-6">
                                    <a href="" className="btn btn-success btn-sm">Make This My Domain</a>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
                <p className="text-end mt-4">
                    <a href="">I want to use my own domain</a> &nbsp; <a href="" className="btn btn-outline-success">Close</a> &nbsp;
                </p>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(SelectDomain);