import * as React from "react";

export default class Card extends React.Component {
    render() {
        return (<div className={'card' + (this.props.className ? ' ' + this.props.className : '')} style={this.props.style}>
            {this.props.title ? (
                <div className="card-header">
                    <div className="d-flex align-items-center">
                        <div className="flex-fill">
                            <h5 className={'card-title'}>{this.props.title}</h5>
                            <div className="card-subtitle">{this.props.subTitle}</div>
                        </div>
                        {this.props.imgSrc ? (<img src={this.props.imgSrc} alt="" className="d-block img-fluid" style={{ maxHeight: '1.35rem' }} />) : ''}
                        {this.props.headerButtons ? (<div className="mx-2">{this.props.headerButtons}</div>) : ''}
                    </div>
                </div>) : ''}
            <div className="card-body">
                {this.props.children}
            </div>
        </div>);
    }
}