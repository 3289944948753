import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AdminSettings extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            dummy: {},
            info: {}
        };
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/admin/settings/get', j => {
            if (j.item) {
                this.setState({ info: j.item, loaded: true });
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    onChange(v) {
        this.setState({ info: { ...this.state.info } });
    }

    submit(e) {
        if (e) e.preventDefault();
        qreq.post('/api/admin/settings/save', { ...this.state.info }, j => {
            if (j.errorCode === 0)
                this.success('Settings saved!');
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    render() {
        return <AdminLayout>
            <div className="container-fluid py-3">
                {this.state.loaded ? <form onSubmit={this.submit}>
                    <Card title="Settings">
                        {this.state.info.logo ? <>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">Logo</label>
                                    <div className="d-grid gap-2">
                                        <img src={'data:' + this.state.info.logo.fileType + ';base64,' + this.state.info.logo.base64Content} alt="" className="img-fluid" />
                                        <button type="button" className="btn btn-danger" onClick={() => this.setState({ info: { ...this.state.info, logo: null } })}>Remove</button>
                                    </div>
                                </div>
                            </div>

                        </> :
                            <FormInput model={this.state.info} name="logo" label="Logo" type="image-upload" onChange={v => this.onChange(v)} />}
                        <div className="mt-3">
                            <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                        </div>
                    </Card>
                </form> : <LoadingAlert />}
            </div>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminSettings);