import * as React from "react";
import { Outlet } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../components/Topbar";

export default class Portal extends React.Component {

    render() {
        return <>
            <main>
                <div className="c-pagewrapper">
                    <Outlet />
                </div>
            </main>
        </>;
    }
}