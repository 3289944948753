import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AdminOrgnaizationsEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.params.id) {
            qreq.get('/api/admin/organization/get?id=' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
    }

    onChange(v) {
        this.setState({ info: { ...this.state.info } });
    }

    submit(e) {
        if (e) e.preventDefault();
        qreq.post('/api/admin/organization/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Organization saved!');
                this.props.navigate('/admin/organizations');
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <AdminLayout>
            <div className="container-fluid">
                <form onSubmit={this.submit}>
                    <Card title="Organization Info">
                        <FormInput model={this.state.info} name="name" label="Organization Name" type="text" />
                        <FormInput model={this.state.info} name="ident" label="Identity" type="text" />
                        <FormInput model={this.state.info} name="dashboardURL" label="Dashboard URL" type="text" />
                        <FormInput model={this.state.info} name="color" label="Color" type="color" />
                        <FormInput model={this.state.info} name="domainRegistryCustomerID" label="Domain Registry Customer ID" type="text" />
                        <FormInput model={this.state.info} name="workflow" label="Workflow" type="select" options={[
                            { id: 'pay-to-publish', name: 'Pay to Publish'},
                            { id: 'pay-to-register', name: 'Pay to Register' }
                        ]} />
                        {this.state.info.logo ? <>
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="form-label">Logo</label>
                                    <div className="d-grid gap-2">
                                        <img src={this.state.info.logo.base64Content ? 'data:' + this.state.info.logo.fileType + ';base64,' + this.state.info.logo.base64Content : Globals.imageUrl(this.state.info.logo.url)} alt="" className="img-fluid" />
                                        <button type="button" className="btn btn-danger" onClick={() => this.setState({ info: { ...this.state.info, logo: null } })}>Remove</button>
                                    </div>
                                </div>
                            </div>

                        </> :
                            <FormInput model={this.state.info} name="logo" label="Logo" type="image-upload" onChange={v => this.onChange(v)} />}
                    </Card>
                    <div className="app-form-buttons">
                        <button onClick={this.goBack} type="button" className="btn btn-secondary"><Icon icon="faCancel" /> Cancel</button>
                        <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    </div>
                </form>
            </div>
        </AdminLayout>
    }
}

export default withParamsAndNavigate(AdminOrgnaizationsEdit);