import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class TestInputSession extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e) {
        e.preventDefault();
        qreq.get('/api/auth/register/session?sid=' + this.state.item.sid, j => {
            if (j.item)
                this.props.navigate('/');
            else if (j.errorCode)
                this.alert(j.errorMessage);
        });
    }

    render() {
        return <div className="container"><Card title="Session">
            <form onSubmit={this.onSubmit}>
                <FormInput model={this.state.item} name="sid" label="Session ID" type="text" required />
                <div className="btn-group">
                    <button type="submit" className="btn btn-primary btn-lg d-block">Set Session</button>
                </div>
            </form>
        </Card></div>;
    }
}

export default withParamsAndNavigate(TestInputSession);