import BaseCom from "./BaseCom";
import Card from "./Card";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link } from "react-router-dom";

class StartPage extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
    }

    render() {
        return <div className="container"><Card>
            <h1>Looks Like You Need A Website!</h1>
            <h2 className="sub"><strong>75%</strong> of users judge a company's credibility based on its website design.<br />Make sure yours is the best in your industry.</h2>
            <div className="c-plans row">
                <div className="col p-4">
                    <Card>
                        <h1>Build It Yourself</h1>
                        <h2 className="mb-5">Use our super simple website builder to launc your very own site today.</h2>
                        <h2><strong>Time:</strong> 30-90 minutes</h2>
                        <div className="stick-bottom">
                            <Link to="/editor" className="fs-3 bolder">Start Building</Link>
                        </div>
                    </Card>
                </div>
                <div className="col p-4">
                    <Card>
                        <h1>Professional Web Design</h1>
                        <h2 className="mb-5">Our team will create a custom website for your business with <strong>UNLIMITED</strong> updates.</h2>
                        <h2><strong>Time:</strong> 7-day process</h2>
                        <div className="stick-bottom">
                            <Link to="/learn" className="fs-3 bolder">Learn More</Link>
                        </div>
                    </Card>
                </div>
            </div>
        </Card></div>
    }
}

export default withParamsAndNavigate(StartPage);