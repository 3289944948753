import BaseCom from "./BaseCom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormInput from "./FormInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

export default class LinkEditor extends BaseCom {
    constructor(props) {
        super(props);
        this.onSubmitLink = this.onSubmitLink.bind(this);
    }

    onSubmitLink(e) {
        e.preventDefault();
        this.setColState({ col: this.state.col, showModal: false });
    }

    render() {
        return <Modal show={this.props.show} onHide={() => this.props.onHide()}>
            <form onSubmit={this.onSubmitLink}>
                <Modal.Header closeButton>
                    <Modal.Title>Link Editor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormInput model={this.props.col} name="body" label="Link Text" type="text" required onChange={this.onChange} />
                    <FormInput model={this.props.col} name="href" label="Link Href" type="text" onChange={this.onChange} />
                </Modal.Body>
                <Modal.Footer>
                    <div className="float-start">
                        <button type="button" className="btn btn-danger" onClick={() => this.props.removeColByID(this.props.col.id)}><FontAwesomeIcon icon={fa.faTrash} /> Remove Link</button>
                    </div>
                    <Button variant="secondary" onClick={() => this.props.onHide()}>
                        Close
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>;
    }
}