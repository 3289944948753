import { Modal } from "react-bootstrap";
import BaseCom from "./BaseCom";
import HTMLEditor from "./HTMLEditor";
import HTMLEditorLink from "./HTMLEditorLink";

export const HTMLEditorGlobal = {
    init(showFunction, hideFunction) {
        this.show = showFunction;
        this.hide = hideFunction;
    },

    show(html, onChange) {
        //overriden on init
    },

    hide() {
        //overriden on init
    }
};

export class HTMLEditorModal extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onChange = this.onChange.bind(this);
        HTMLEditorGlobal.init(this.show, this.hide);
    }

    show(html, onChange) {
        this.setState({ value: html, showModal: true, onChange: onChange });
    }

    hide() {
        this.setState({ value: '', showModal: false, onChange: null });
    }

    onChange(v) {
        this.setState({ value: v });
        if (this.state.onChange)
            this.state.onChange(v);
    }

    render() {
        return <>
        <HTMLEditorLink />
        <Modal show={this.state.showModal} onHide={() => this.hide()} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Editor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <HTMLEditor value={this.state.value} onChange={this.onChange} />
            </Modal.Body>
            <Modal.Footer>
                <small>Changes are automatically applied.</small>
                <button type="button" className="btn btn-secondary btn-lg" onClick={() => this.hide()}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
        </>;
    }
}