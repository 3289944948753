import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Session extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        qreq.post('/api/auth/session', { OTSID: this.props.params.sid }, j => {
            if(j.errorCode === 0)
                window.location.replace('/');
            else
                this.setState({ error: j.errorMessage });
            this.setState({ loading: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ loading: false });            
        });
    }

    render() {
        if(this.state.loading)
            return <div className="container-fluid"><h1>Loading</h1><LoadingAlert /></div>;

        if(this.state.error)
            return <div className="container-fluid"><h1>Error</h1><Alert type="danger">{this.state.error}</Alert></div>;

        return <></>;
    }
}

export default withParamsAndNavigate(Session);