import React from 'react';
import { EditorCol } from './EditorCol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class EditorContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            container: props.container,
            cols: props.container.cols,
            parentId: props.parentId,
            parentCol: props.parentCol
        };
        this.subContainer = props.subContainer;
        this.idDiv = 'wbp-container-' + this.state.container.id;
        this.setSelectedColumn = props.setSelectedColumn;
        this.focusMove = props.focusMove;
        this.setMainToolbarStatus = props.setMainToolbarStatus;
        this.createCol = props.createCol;
        this.removeCol = props.removeCol;
        this.removeColByID = props.removeColByID;
        this.showComProps = props.showComProps;
        this.getExtraClasses = this.getExtraClasses.bind(this);
    }
       

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.container !== this.props.container)
            this.setState({ container: this.props.container, cols: this.props.container.cols });
        else if (prevState.cols !== this.props.container.cols)
            this.setState({ cols: this.props.container.cols });
        if (prevProps.parentId !== this.props.parentId)
            this.setState({ parentId: this.props.parentId });
        if (prevProps.parentCol !== this.props.parentCol)
            this.setState({ parentCol: this.props.parentCol });
    }

    getExtraClasses() {
        var r = '';
        if (this.state.container.alignItems)
            r += ' align-items-' + this.state.container.alignItems;
        if (this.state.container.justifyContent)
            r += ' justify-content-' + this.state.container.justifyContent;
        if(this.state.container.rowCols)
            r += ' row-cols-md-' + this.state.container.rowCols;
        return r;
    }



    render() {


        if ((this.state.parentId && this.state.cols.length === 0) || (this.state.parentCol && this.state.parentCol.com && this.state.parentCol.com.editor === 'menu')) {

            /*
            if (this.state.cols.length === 0) {
                return <div className="text-muted p-2"><i>This container is empty.</i></div>;
            }*/

            return <>{this.state.cols.map(a => (
                <EditorCol key={a.id} snap={this.props.snap} subContainer={this.state.container.subContainer} parentId={this.state.parentId} createCol={this.createCol} removeCol={this.removeCol} removeColByID={this.removeColByID} focusMove={this.focusMove} container={this.state.container} col={a} setSelectedColumn={this.setSelectedColumn} setMainToolbarStatus={this.setMainToolbarStatus} showComProps={this.showComProps} />
            ))}</>;
        }

        
        return (
            <section style={{ ...this.state.container.sectionStyle }}>
                <div className={this.state.container.containerType && this.state.container.containerType === 'fluid' ? 'container-fluid' : this.props.metadata && this.props.metadata.props && this.props.metadata.props.containerType === 'fluid' ? 'container-fluid' : 'container'}>
                    <div id={this.idDiv} className={'row wbp-container ' + this.getExtraClasses()} wbp-container-id={this.state.container.id}>
                        {this.state.cols.map(a => (
                            <EditorCol key={a.id} snap={this.props.snap} subContainer={this.state.container.subContainer} parentId={this.state.parentId} createCol={this.createCol} removeCol={this.removeCol} removeColByID={this.removeColByID} focusMove={this.focusMove} container={this.state.container} col={a} setSelectedColumn={this.setSelectedColumn} setMainToolbarStatus={this.setMainToolbarStatus} showComProps={this.showComProps} />
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}