import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import * as React from 'react';

export default class Alert extends React.Component {

    render() {
        return (<div className={'alert alert-' + (this.props.type ?? 'info') + (this.props.className ? ' ' + this.props.className : '')} onClick={this.props.onClick}>
            <FontAwesomeIcon icon={fa['fa' + (this.props.icon ? this.props.icon : 'InfoCircle')]} className="me-2" />
            {this.props.children ?? ''}
            {this.props.message ?? ''}
        </div >);
    }
}

