import BaseCom from "./BaseCom";
import CodeEditor from "./CodeEditor";
import CodeEditorModal from "./CodeEditorModal";
import FormInput from "./FormInput";
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form'

export default class PropertiesEditor extends BaseCom {

    constructor(props) {
        super(props);

        if (props.container && !props.container.sectionStyle)
            props.container.sectionStyle = {};

        this.state = {
            col: props.col,
            container: props.container,
            sizes: [],
            textProps: {},
            fontSizes: [],
            pixels: [],
            containerTypes: [
                { id: 'fluid', name: 'Fluid' },
                { id: 'default', name: 'Centered' }
            ],
            pageProps: props.pageProps
        };



        for (var n = 10; n < 32; n++) {
            this.state.fontSizes.push({ id: n, name: String(n) });
        }

        for (var n = 1; n <= 12; n++) {
            this.state.sizes.push({ id: n, name: String(n) });
        }

        for (var n = 0; n <= 10; n++) {
            var px = String(n) + 'px';
            this.state.pixels.push({ id: px, name: px });
        }

        if (!this.state.col.theme)
            this.state.col.theme = {};
        if (!this.state.col.domain)
            this.state.col.domain = {};

        this.onChange = this.onChange.bind(this);
        this.onPropChange = props.onChange;
        this.onCodeEditChange = this.onCodeEditChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.col !== this.props.col) {
            if (this.props.col && !this.props.col.theme)
                this.props.col.theme = {};
            this.setState({ col: this.props.col });
        }
        if (prevProps.container !== this.props.container) {
            if (this.props.container && !this.props.container.sectionStyle)
                this.props.container.sectionStyle = {};
            this.setState({ container: this.props.container });
        }
    }

    onChange(e) {

        if(this.state.col.childStyle && this.state.col.containers.length > 0 && this.state.col.containers[0].cols) {
            this.state.col.containers[0].cols.forEach(i => {
                for(var k in this.state.col.childStyle) {
                    i.style[k] = this.state.col.childStyle[k];
                }    
            });
        }
        
        if (this.props.onPropChange)
            this.props.onPropChange(e);
    }

    onContainerChange(e) {
        if (this.props.onContainerPropChange)
            this.props.onContainerPropChange(e);
    }

    onCodeEditChange(v) {
        if(this.state.codeEditItem) {
            this.state.codeEditItem[this.state.codeEditProp] = v;
            this.onChange();
        }
    }

    render() {
        if (!this.state.col)
            return <></>;
        
        return <>
            <CodeEditorModal code={this.state.codeEditItem ? this.state.codeEditItem[this.state.codeEditProp] : ''} onChange={this.onCodeEditChange} show={this.state.showCodeEdit} onHide={() => this.setState({ showCodeEdit: false, codeEditItem: null, codeEditProp: null })} />    
            <Accordion defaultActiveKey="0">

                {!this.state.pageProps ? <Accordion.Item eventKey="0">
                    <Accordion.Header>Basic</Accordion.Header>
                    <Accordion.Body>
                        {!this.props.pageProps ? <FormInput type="select" model={this.state.container} name="containerType" label="Container Type" options={this.state.containerTypes} onChange={this.onChange} /> : ''}

                        
                        {!this.props.pageProps ? <FormInput model={this.state.col} name="size" label="Column Size" type="select" options={this.state.sizes} onChange={this.onChange} /> : ''}
                        {this.state.col.com && this.state.col.com.editor === 'image' ? <><FormInput model={this.state.col} name="image" label="Image" type="image-upload" onChange={this.onImageUpload} />
                            {this.state.col.imageUpload ? <><img src={'data:' + this.state.col.imageUpload.fileType + ';base64,' + this.state.col.imageUpload.base64Content} alt="" className="img-fluid img-thumbnail" /></> : ''}
                        </> : ''}
                    </Accordion.Body>
                </Accordion.Item> : ''}

                {!this.state.pageProps ?
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Element</Accordion.Header>
                        <Accordion.Body>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="display" label="Display" selectLabel="Default" type="select" options={[
                                        { id: 'block', name: 'Block' },
                                        { id: 'inline-block', name: 'Inline Block' },
                                        { id: 'flex', name: 'Flex' },
                                        { id: 'none', name: 'None' }
                                    ]} onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="position" label="Position" selectLabel="Default" type="select" options={[
                                        { id: 'static', name: 'Static' },
                                        { id: 'relative', name: 'Relative' },
                                        { id: 'absolute', name: 'Absolute' },
                                        { id: 'fixed', name: 'Fixed' }
                                    ]} onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="top" label="Top" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="left" label="Left" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="bottom" label="Bottom" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="right" label="Right" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item> : ''}

                

                <Accordion.Item eventKey="2">
                    <Accordion.Header>Styling</Accordion.Header>
                    <Accordion.Body>
                        <div className="card adjusted-card">
                            <div className="row">
                                <div className="text-center mb-2">
                                    Color
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-6 col-12">
                                    {/* <label>Background Color</label>
                                    <Form.Control type="color" model={this.state.col.style} name="backgroundColor" label="Background" onChange={this.onChange} value={'#000'} />*/}
                                    <FormInput model={this.state.col.style} name="backgroundColor" label="Background" type="color" onChange={this.onChange} /> 
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="color" label="Text" type="color" onChange={this.onChange} />
                                </div>
                                {this.props.pageProps ?
                                    <>
                                        <div className="col-md-6 col-12">
                                           <FormInput model={this.state.col.theme} name="primaryColor" label="Primary" type="color" onChange={this.onChange} />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <FormInput model={this.state.col.theme} name="linkColor" label="Link" type="color" onChange={this.onChange} />
                                        </div>
                                    </> : ''}
                            </div> 
                        </div>
                        
                        {this.state.col.com && this.state.col.com.editor === 'menu' ? <div className="card adjusted-card">
                            <div className="row">
                                <div className="text-center mb-2">
                                    Menu Item
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.childStyle} name="color" label="Text" type="color" onChange={this.onChange} />
                                </div>
                            </div>
                        </div> : ''}
                           
                        <div className="card adjusted-card">
                            <div className="row">
                                <div className="text-center mb-2">
                                    Margins
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="margin-top" label="Top" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="margin-right" label="Right" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="margin-bottom" label="Bottom" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="margin-left" label="Left" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                            </div>
                        </div>

                        <div className="card adjusted-card">
                            <div className="row">
                                <div className="text-center mb-2">
                                    Padding
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="padding-top" label="Top" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="padding-right" label="Right" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="padding-bottom" label="Bottom" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="padding-left" label="Left" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                            </div>
                        </div>

                           
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.col.style} name="height" label="Height" type="number" placeholder="auto" max={1000} postpend="px" onChange={this.onChange} />
                            </div>
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.col.style} name="maxHeight" label="Max Height" type="number" placeholder="auto" max={1000} postpend="px" onChange={this.onChange} />
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Typography</Accordion.Header>
                    <Accordion.Body>

                        <div className="card adjusted-card">
                            <div className="row">
                                <div className="text-center mb-2">
                                    Fonts
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="fontFamily" label="Font Family" selectLabel="Default" type="select" options={[
                                        { id: 'Arial, Helvetica, sans-serif', name: 'Arial' }, { id: 'Arial Black, Gadget, sans-serif', name: 'Arial Black' }, { id: 'Brush Script MT, sans-serif', name: 'Brush Script MT' }, { id: 'Comic Sans MS, cursive, sans-serif', name: 'Comic Sans MS' }, { id: 'Courier New, Courier, monospace', name: 'Courier New' }, { id: 'Georgia, serif', name: 'Georgia' }, { id: 'Helvetica, sans-serif', name: 'Helvetica' }, { id: 'Impact, Charcoal, sans-serif', name: 'Impact' }, { id: 'Lucida Sans Unicode, Lucida Grande, sans-serif', name: 'Lucida Sans Unicode' }, { id: 'Tahoma, Geneva, sans-serif', name: 'Tahoma' }, { id: 'Times New Roman, Times, serif', name: 'Times New Roman' }, { id: 'Trebuchet MS, Helvetica, sans-serif', name: 'Trebuchet MS' }, { id: 'Verdana, Geneva, sans-serif', name: 'Verdana' }
                                    ]} onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="fontSize" label="Font Size" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                </div>
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="fontWeight" label="Font Weight" type="select" selectLabel="auto" options={[{ id: '100', name: 'Thin' }, { id: '200', name: 'Extra-Light' }, { id: '300', name: 'Light' }, { id: '400', name: 'Normal' }, { id: '500', name: 'Medium' }, { id: '600', name: 'Semi-Bold' }, { id: '700', name: 'Bold' }, { id: '800', name: 'Extra-Bold' }, { id: '900', name: 'Ultra-Bold' }]} onChange={this.onChange} />
                                </div>
                               
                                <div className="col-md-6 col-12">
                                    <FormInput model={this.state.col.style} name="color" label="Text Color" type="color" onChange={this.onChange} />
                                </div>

                            </div>
                        </div>    
                            <div className="card adjusted-card">
                                <div className="row">
                                    <div className="text-center mb-2">
                                        Spacing
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-12">
                                        <FormInput model={this.state.col.style} name="letterSpacing" label="Letter Spacing" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <FormInput model={this.state.col.style} name="lineHeight" label="Line Height" type="number" placeholder="auto" max={2500} postpend="px" onChange={this.onChange} />
                                    </div>
                                </div>

                    </div>

                    </Accordion.Body>
                </Accordion.Item>
                {this.state.container && this.state.container.sectionStyle ? <Accordion.Item eventKey="9">
                    <Accordion.Header>Section</Accordion.Header>
                    <Accordion.Body>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.container} name="rowCols" label="Columns" type="select" options={[
                                    { id: '1', name: '1' },
                                    { id: '2', name: '2' },
                                    { id: '3', name: '3' },
                                    { id: '4', name: '4' },
                                    { id: '5', name: '5' },
                                    { id: '6', name: '6' }
                                ]} />
                            </div>
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.container} name="justifyContent" label="Align" type="select" options={[
                                    { id: 'start', name: 'Start' },
                                    { id: 'end', name: 'End' },
                                    { id: 'center', name: 'Center' },
                                    { id: 'evenly', name: 'Evenly' }
                                ]} onChange={this.onChange} />
                            </div>
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.container} name="alignItems" label="Vertical Align" type="select" options={[
                                    { id: 'start', name: 'Start' },
                                    { id: 'end', name: 'End' },
                                    { id: 'center', name: 'Center' },
                                    { id: 'baseline', name: 'Baseline' },
                                    { id: 'stretch', name: 'Stretch' }
                                ]} onChange={this.onChange} />
                            </div>
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.container.sectionStyle} name="backgroundColor" label="BG Color" type="color" onChange={this.onChange} />
                            </div>
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.container} name="backgroundImage" label="BG Image" buttonLabel="Image" type="image-upload" onChange={e => {
                                    this.state.container.sectionStyle.backgroundImage = 'url(\'data:' + this.state.container.backgroundImage.fileType + ';base64,' + this.state.container.backgroundImage.base64Content + '\')';
                                    this.onChange(e);
                                }} />
                                {this.state.col.imageUpload ? <><img src={'data:' + this.state.container.backgroundImage.fileType + ';base64,' + this.state.container.backgroundImage.base64Content} alt="" className="img-fluid img-thumbnail" /></> : ''}
                            </div>
                            <div className="col-md-6 col-12">
                                <FormInput model={this.state.container.sectionStyle} name="backgroundSize" label="BG Size" type="select" options={[
                                    { id: 'initial', name: 'Initial' },
                                    { id: 'cover', name: 'Cover' },
                                    { id: 'contain', name: 'Contain' }
                                ]} onChange={this.onChange} />
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item> : ''}
                <Accordion.Item eventKey="10">
                    <Accordion.Header>Advanced</Accordion.Header>
                    <Accordion.Body>
                        {this.state.pageProps ? <>
                            <div className="d-grid gap-1 my-1"><button type="button" className="btn btn-secondary" onClick={() => this.setState({ codeEditItem: this.state.col, codeEditProp: 'css', showCodeEdit: true })}>Edit Page CSS Styles</button>
                            {this.state.col.domain ? <button type="button" className="btn btn-secondary" onClick={() => this.setState({ codeEditItem: this.state.col.domain, codeEditProp: 'css', showCodeEdit: true })}>Edit Website CSS Styles</button> : ''}
                            </div>
                        </> : ''}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>;
    }
}