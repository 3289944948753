import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import LoadingAlert from "./LoadingAlert";

export default class ShutterStockGallery extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            filter: {
                query: ''
            },
            list: [],
            components: props.components
        };
        this.checkComs(props.components);
        this.load = this.load.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        this.checkComs(this.state.components);
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.components !== this.props.components) {
            this.setState({ components: this.props.components });
            this.checkComs(this.props.components);
        }
    }

    checkComs(components, list) {

        var imgCom = null;
        components.forEach(i => {
            if(i.editor === 'image') {
                imgCom = i;
                this.setState({ imageComID: i.id, imgCom: i });
            }
        });
        if(imgCom) {
            if(!list)
                list = [...this.state.list];
            list.forEach(i => {
                i.com = {...imgCom};
                i.com.extraProps = {
                    image: {
                        url: i.assets.preview.url,
                        alt: i.description
                    }
                };
            });
            this.setState({ list: list });
        }
    }

    load(query) {
        if(!query)
            query = this.state.filter.query;
        qreq.get('/api/media/shutterstock/list?query=' + query, j => {
            if(j.list) {
                this.setState({ list: j.list });
                this.checkComs(this.props.components, j.list);
            }
            else if(j.errorCode)
                this.setState({ error: j.errorMessage });
            else
                this.setState({ error: 'Failed to load images.' });
            this.setState({ loaded: true });
        }, () => {
            this.setState({ error: 'Failed to load images.', loaded: true });
        });
    }

    search() {
        if(this.searchTimer)
            clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => this.load(), 1000);
    }

    render() {
        if(!this.state.loaded || !this.state.components)
            return <LoadingAlert />;

        if(this.state.error)
            return <Alert type="danger">{this.state.error}</Alert>;

        return <div className="ShutterStockGallery">
            <FormInput model={this.state.filter} name="query" type="text" label="Search images..." labelAsPlaceholder onChange={() => this.search()} />
            <div className="row row-cols-2 justify-content-center align-items-center">
                {this.state.list.map(i => <div key={i.id} className="col mt-3">
                    <img id={'c-com-' + this.state.imageComID} src={i.assets.preview.url} alt="" className="img-fluid drop-shadow move" onMouseDown={e => this.props.focusMove(e, i.com)} />
                </div>)}
            </div>
        </div>;
    }
}