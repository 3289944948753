import { Modal } from "react-bootstrap"
import BaseCom from "./BaseCom"
import { qreq } from "../shared/qrequest";

export default class AboutModal extends BaseCom {
    componentDidMount() {
        qreq.get('/api/version', j => {
            this.setState({ version: j.item });
        });
    }

    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>About Site Builder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>A web-based tool for creating and editing websites.</p>
                <p>Developed exclusively for <strong><a href="http://taplocalnow.com" target="_blank" rel="noopener noreferrer">TapLocal Now</a></strong> and <strong><a href="https://banktechpr.com" target="_blank" rel="noopener noreferrer">BankTech PR</a></strong>.</p>
                <p>For more information about the developer <strong>Solusek Systems</strong>, visit <a href="https://solusek.com" target="_blank" rel="noopener noreferrer">solusek.com</a>.</p>
                <p>Release: v{this.state.version}</p>
            </Modal.Body>
        </Modal>;
    }
}