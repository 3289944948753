import Auth from "../shared/Auth";
import BaseCom from "./BaseCom";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Index extends BaseCom {
    componentDidMount() {
        Auth.getLogin(u => {
            if (u) {
                if (u.flags.indexOf('admin') >= 0)
                    this.props.navigate('/admin');
                else
                    this.props.navigate('/editor');
            }
            else
                this.props.navigate('/login');
        });
    }

    render() {
        return <LoadingAlert />;
    }
}

export default withParamsAndNavigate(Index);