import './LauncherModal.css';
import { Link } from "react-router-dom";
import BaseCom from "./BaseCom";
import TemplateModal from "./TemplateModal";
import CloseButton from "react-bootstrap/CloseButton";
import SelectTemplate from "./SelectTemplate";
import Icon from './Icon';
import SelectTemplateMode from './SelectTemplateMode';
import AISurvey from './AISurvey';
import { qreq } from '../shared/qrequest';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import DomainSearch from './DomainSearch';

class LauncherModal extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            page: 'initial'
        };
        this.selectTemplate = this.selectTemplate.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/organization/get/current', j => {
            if (j.item) {
                if(j.item.workflow === 'Pay to Publish')
                    this.setState({ page: 'domain-select' });
                this.setState({ organization: j.item });
            }
        });
    }

    selectTemplate(obj) {
        this.setState({ selecting: true });
        qreq.post('/api/editor/template/select?siteDomainID=' + (this.props.params.templateID ?? '0'), {...obj}, j => {
            if(j.errorCode === 0) {
                this.success('Template successfully loaded.');
                window.location.reload();
            }
            else {
                this.alert(j.errorMessage);
                this.setState({ selecting: false });
            }
        }, () => {
            this.unkownErrorCallback();
            this.setState({ selecting: false });
        });
    }

    render() {
        if(!this.props.show)
            return <></>;
        return <>
        <div className="fade modal-backdrop show"></div>
        <div className="modal fade show LauncherModal" role="dialog" style={{display: 'block'}}>
            <div className={'modal-dialog modal-dialog-centered' + (this.state.page === 'template' ? ' modal-lg' : ' modal-xl')}>
                <div className="modal-content modal-content-style" style={{backgroundColor: '#fedab300', border: 'none'}}>
                    {this.state.page === 'template-select' ? <SelectTemplate onSelect={tpl => this.setState({ tpl: tpl, page: 'template' })} onBack={() => this.setState({ page: 'initial' })} onClose={this.props.onClose} />
                    : this.state.page === 'domain-select' ? <DomainSearch noModal reserveOnly onSuccess={() => this.setState({ page: 'initial' })} />
                    : this.state.page === 'template' ? <SelectTemplateMode onBack={() => this.setState({ page: 'template-select' })} setPage={v => this.setState({ page: v })} tpl={this.state.tpl} onSelect={this.selectTemplate} />
                    : this.state.page === 'template-ai' ? <AISurvey onBack={() => this.setState({ page: 'template' })} setPage={v => this.setState({ page: v })} tpl={this.state.tpl} onComplete={this.props.onClose} />
                    : <div className="container-fluid mt-2">
                        {this.props.onClose ?
                        <span className="float-end fs-2 text-white">
                            <span role="button" title="Close" aria-label="Close" onClick={this.props.onClose}>
                                <Icon icon="faTimes" />
                            </span>
                        </span> : ''}
                        <h1 className="text-center mb-3 modal-header-weight text-shadow">
                            Welcome to Your Web Builder.
                        </h1>
                        <div className="row align-items-stretch">
                            <div className="col-lg-6 col-md-12">
                                <div className="card custom-card3 mb-4">
                                    <div className="card-body">
                                        <h4 className="welcome-title">
                                            Build Your Own Site
                                        </h4>
                                        <div className="row">
                                            <div className="col-lg-6 welcome-card-text">
                                                Using our easy to understand tools you can build your own
                                                website from scratch! Bring your vision to life, have it
                                                deliver your message and branding, and when complete
                                                publish it to the world!
                                            </div>
                                            <div className="col-lg-6 text-center">
                                                <div>
                                                    <img src="/img/launcher01.png" className="img-fluid welcome-img-1" alt="with the pros" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-xl-block">
                                            <button className="btn btn-success text-body" onClick={this.props.onClose} data-bs-dismiss="modal">Get Started</button>
                                        </div>
                                    </div>
                                    <div className="card-footer d-xl-none">
                                        <button className="btn btn-success btn-block text-body" onClick={this.props.onClose} data-bs-dismiss="modal">Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="card custom-card3 mb-4">
                                    <div className="card-body">
                                        <h4 className="welcome-title">
                                            Begin Using Our Templates
                                        </h4>
                                        <div className="row">
                                            <div className="col-lg-6 welcome-card-text">
                                                Streamline your creative process by
                                                using one of our expertly-designed
                                                websites. You’ll be able to fully
                                                customize the site and use AI to
                                                write content for your business.
                                            </div>
                                            <div className="col-lg-6 text-center">
                                                <div>
                                                    <img src="/img/launcher02.png" className="img-fluid welcome-img-2" alt="with the pros" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-xl-block">
                                            <button type="button" className="btn btn-success text-body" onClick={() => this.setState({ page: 'template-select' })}>Get Started</button>
                                        </div>
                                    </div>
                                    <div className="card-footer d-xl-none">
                                        <button type="button" className="btn btn-success text-body" onClick={() => this.setState({ page: 'template-select' })} data-bs-toggle="modal" data-bs-target="#beginusingtemplates">Get Started</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  align-items-stretch">
                            <div className="col-lg-6 col-md-12">
                                <div className="card custom-card3 mb-4">
                                    <div className="card-body">
                                        <h4 className="welcome-title">
                                            Professional Web Design
                                        </h4>
                                        <div className="row">
                                            <div className="col-lg-6 welcome-card-text">
                                                Let our team of design experts
                                                build a custom website for your
                                                business. We’ll provide a draft
                                                for your approval within 5
                                                business days and will work
                                                with you to tweak your site to
                                                fit your needs.
                                            </div>
                                            <div className="col-lg-6 text-center">
                                                <div>
                                                    <img src="/img/launcher03.png" className="img-fluid welcome-img-3" alt="with the pros" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-xl-block">
                                            <button type="button" className="btn btn-success text-body" data-bs-toggle="modal" data-bs-target="#proweb">Get Started</button>
                                        </div>
                                    </div>
                                    <div className="card-footer d-xl-none">
                                        <button className="btn btn-success btn-block text-body" n="" data-bs-toggle="modal" data-bs-target="#proweb">Get Started</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="card custom-card3 mb-4">
                                    <div className="card-body">
                                        <h4 className="welcome-title">
                                            Schedule A Call
                                        </h4>
                                        <div className="row">
                                            <div className="col-lg-6 welcome-card-text">
                                                Don’t know if you want to DIY or let
                                                our team take over the design process?
                                                Talk with a small business expert to
                                                find the best solution for your
                                                business. We’re here to help you
                                                make your vision a reality.
                                            </div>
                                            <div className="col-lg-6 text-center">
                                                <div>
                                                    <img src="/img/launcher04.png" className="img-fluid welcome-img-4" alt="with the pros" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-none d-xl-block">
                                            <a href="#" className="btn btn-success text-body">Get Started</a>
                                        </div>
                                    </div>
                                    <div className="card-footer d-xl-none">
                                        <a href="#" className="btn btn-success btn-block text-body">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        </>;
    }
}

export default  withParamsAndNavigate(LauncherModal);