import BaseCom from "./BaseCom"
import Breadcrumb from "./Breadcrumb";
import LoadingAlert from "./LoadingAlert";
import withParams from "./WithParams";
import { qreq } from "../shared/qrequest";
import Auth from "../shared/Auth";
import Card from "./Card";
import FormInput from "./FormInput";
import Alert from "./Alert";
import Globals from "../shared/Globals";
import AdminLayout from "./AdminLayout";
import Icon from "./Icon";
import { Pop } from "./PopModal";

class User extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            item: props.item,
            accounts: [],
            roles: [],
            roleGroups: []
        };

        if (!this.props.item.id)
            this.props.item.isActive = true;

        this.isStarRole = this.isStarRole.bind(this);
        this.canEditRoles = this.canEditRoles.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u, loaded: true });

            qreq.get('/api/account/list', j => {
                if (j.errorCode === 0)
                    this.setState({ accounts: j.list });
            });

            qreq.get('/api/user/role/list', j => {
                if (j.errorCode === 0) {
                    var list = [];
                    j.list.forEach(i => {
                        if (i.isAssignable)
                            list.push(i);
                    });
                    this.setState({ roleGroups: list });
                }
            });

            if (this.props.item.flags) {
                this.props.item.flags.forEach((i) => {
                    this.state.roles.forEach((j) => {
                        if (j.id === i)
                            j.selected = true;
                    });
                });
            }
           
            if (this.isStarRole()) {
                this.setState({
                    roles: [
                        { id: 'admin', name: 'Administrator' },
                        { id: 'account-manager', name: 'Account Manager' },
                        { id: 'field', name: 'Field' },
                        { id: 'inventory', name: 'Inventory Manager' }
                    ]
                });
            }
            else {
                this.setState({
                    roles: [
                        { id: 'account-manager', name: 'Account Manager' },
                        { id: 'field', name: 'Field' },
                        { id: 'inventory', name: 'Inventory Manager' }
                    ]
                });
            }
        });
        
    }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item) {
            this.setState({ item: this.props.item });
        }
    }

    canEditRoles() {
        if (this.props.item.flags.indexOf('*') !== -1)
            return false;
        if (this.props.item.flags.indexOf('master') !== -1 && !Auth.hasFlag('*'))
            return false;
        if (this.props.item.flags.indexOf('admin') !== -1 && !Auth.hasFlag('master'))
            return false;
        return true;
    }

    isStarRole() {
        return Auth.hasFlag('*');
    }

    onChange() {
        if (this.props.onChange)
            this.props.onChange();
    }

    onRoleChange(i) {
        if (i) {
            i = Number(i);
            var rg = this.state.roleGroups.find(j => j.id === i);
            if (rg) {
                if (this.props.onRoleChange)
                    this.props.onRoleChange(rg.roles);
            }
        }
        if (this.props.onRoleChange)
            this.props.onRoleChange(rg.roles);
    }

    render() {
        if (!this.state.loaded)
            return <LoadingAlert />
        return (
            <div>
                <Card title="User Info" className="mb-3" headerButtons={!this.props.item?.flags?.some(a => a === 'master') && <button type="button" className="btn btn-danger" onClick={() => {
                    Pop.showConfirm('Are you sure you want to delete this user?', 'Delete User', () => {
                        qreq.post('/api/user/delete', { id: this.props.item.id }, j => {
                            if (j.errorCode === 0) {
                                this.success('User has been deleted.');
                                this.goBack();
                            }
                            else
                                this.alert(j.errorMessage);
                        }, this.unkownErrorCallback);
                    });
                }}><Icon icon="faTrash" /> Delete</button>}>
                    <FormInput model={this.props.item} label="Email Address/Username" name="email" type="email" required={true} onChange={this.onChange} />
                    {this.canEditRoles() ?
                        <div className="row">
                            <div className="col">
                                <label className="form-label">* Role</label>
                                <p>Administrator</p>
                            </div>
                        </div> : ''}
                    <FormInput model={this.props.item} label="Full Name" name="fullName" type="text" required onChange={this.onChange} />
                </Card>
                <Card title="Options" className="mb-3">
                    {this.props.item.id !== 0 ? <FormInput model={this.props.item} label="Send email invitation" name="resendInvite" type="switch" onChange={this.onChange} /> : ''}
                    {this.props.item.resendInvite ? <Alert message="An email will be sent to this user with a link to set their credentials when saved." /> : ''}
                </Card>
            </div>
        );

    }
}

class AdminUsersEdit extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            item: {
                flags: []
            },
            saving: false
        };
        

        this.submit = this.submit.bind(this);

    }

    componentDidMount() {
        this.setState({ loading: true });
        if (this.props.params.id && Number(this.props.params.id) !== 0) {
            qreq.get('/api/user/view/' + this.props.params.id, j => {
                if (j.errorCode)
                    alert(j.errorMessage);
                else {
                    this.state.item = j.item;
                    this.setState({ item: this.state.item });
                }
                this.setState({ loading: false });
            }, e => {
                this.goBack();
            });
        }
        else
            this.setState({ loading: false });
    }

    submit(e) {
        e.preventDefault();
        this.setState({ saving: true });

        qreq.post('/api/user/save', this.state.item, (j) => {
            if (j.errorCode !== 0)
                alert(j.errorMessage);
            else {
                this.success('User has been saved.');
                this.goBack();
            }

            this.setState({ saving: false });
        });
    }

    render() {
        if (this.state.loading)
            return (<div className="container-fluid"><h1>Edit User</h1><LoadingAlert /></div>);

        return <AdminLayout>
            <div className="container-fluid">
                <form onSubmit={this.submit}>
                    <h1>Edit User</h1>
                    <Breadcrumb path={[['Users', '/admin/users'], ['Edit']]} />
                    <User item={this.state.item} edit={true} onChange={() => this.setState({ item: { ...this.state.item } })} onRoleChange={roles => this.setState({ item: { ...this.state.item, roles: roles } })} />
                    <div className="app-form-buttons">
                        <button type="button" className="btn btn-secondary d-inline-block ms-1" onClick={this.goBack} disabled={this.state.saving}><Icon icon="faCancel" /> Cancel</button>
                        <button id="c-submit" type="submit" className="btn btn-success d-inline-block" disabled={this.state.saving}><Icon icon="faSave" /> Save</button>
                    </div>
                </form>
            </div>
        </AdminLayout>;
    }
}

export default withParams(AdminUsersEdit);