import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class DomainSearch extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            query: {}
        };
        this.search = this.search.bind(this);
        this.select = this.select.bind(this);
    }

    search(e) {
        if (e) e.preventDefault();
        if (!this.state.query.search)
            this.setState({ list: [] });
        qreq.get('/api/domain/search?query=' + encodeURIComponent(this.state.query.search), j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    select(item) {
        qreq.post('/api/domain/select' + (this.props.reserveOnly ? '?reserveOnly=true' : ''), item, j => {
            if (j.redirectUrl)
                window.location.assign(j.redirectUrl);
            else if (this.props.reserveOnly && j.errorCode === 0)
                this.props.onSuccess();
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    render() {
        const content = <div className="container-fluid">
            <form onSubmit={this.search}>
                <div className="d-flex align-items-center gap-1">
                    <FormInput model={this.state.query} name="search" type="text" label="mydomainname.com" labelAsPlaceholder />
                    <button type="submit" className="btn btn-primary mb-3 rounded-0">Check</button>
                </div>
                {this.state.list ? <div className="list-group rounded-0">
                    {this.state.list.map(i => <div key={i.key} className="list-group-item d-flex align-items-center" style={{ height: '50px' }}>
                        <div className="flex-fill">
                            <span className={i.isAvailable ? 'text-success' : 'text-danger'}>
                                {i.domainName}
                            </span>
                            <span className="d-inline-block ms-1">
                                {i.isAvailable ? <div className="badge bg-success">Available</div> : <div className="badge bg-danger">Taken</div>}
                            </span>
                        </div>
                        {i.isAvailable ? <div>
                            <button type="button" className="btn btn-success" onClick={() => this.select(i)}>Buy Now!</button>
                        </div> : ''}
                    </div>)}
                </div> : null}
            </form>
        </div>;
        if (this.props.noModal)
            return content;
        else
            return this.wrapModal(content, 'Domain Search', 'xl');
    }
}

export default withParamsAndNavigate(DomainSearch);