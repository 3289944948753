import React from 'react';
import BaseCom from './BaseCom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import LoadingAlert from './LoadingAlert';
import { qreq } from '../shared/qrequest';
import DataTable from './DataTable';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import Alert from './Alert';
import AdminLayout from './AdminLayout';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import Card from './Card';

class AdminUsers extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            list: [],
            loading: true,
            loaded: false
        };
        this.load = this.load.bind(this);
        this.impersonate = this.impersonate.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        qreq.get('/api/user/list', j => {
            if (j.errorCode)
                this.setState({ errorMessage: j.errorMessage });
            else {
                this.state.list = j.list;
                this.setState({ list: this.state.list });
            }
            this.setState({ loading: false, loaded: true });
        }, () => {
            this.setState({ loading: false, loaded: true, errorMessage: 'Unknown error ocurred.' });
        });
    }

    impersonate(user) {
        qreq.post('/api/user/impersonate', user, j => {
            if (j.errorCode === 0)
                document.location.replace('/');
            else
                this.alert(j.errorMessage);
        });
    }

    render() {

        if (!this.state.loaded)
            return (<div className="container-fluid"><LoadingAlert /></div>);

        if (this.state.errorMessage)
            return <Alert type="danger" icon="ExclamationTriangle" message={this.state.errorMessage} />


        return <AdminLayout>
            <div className="container-fluid">
                <Card title="Users" headerButtons={<><Link to="/admin/users/edit/0" className="btn btn-primary"><Icon icon="faPlus" /> New User</Link></>}>
                    <DataTable columns={[
                        { icon: 'Pencil', link: '/admin/users/edit/:id', tooltip: 'Edit User' },
                        { name: 'id', title: 'ID', link: '/admin/users/edit/:id', tooltip: 'Edit User', className: 'd-none d-md-table-cell' },
                        { name: 'fullName', title: 'Full Name', link: '/admin/users/edit/:id', tooltip: 'Edit User' },
                        { name: 'username', title: 'Email/Username', link: '/admin/users/edit/:id', tooltip: 'Edit User' },
                        { name: 'createdDate', title: 'Created', format: 'datetime', className: 'd-none d-md-table-cell' }
                    ]} data={this.state.list} loading={this.state.loading} />
                </Card>
            </div>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminUsers);