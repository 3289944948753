import BaseCom from "./BaseCom";
import CloseButton from "react-bootstrap/CloseButton";

export default class TemplateModal extends BaseCom {
    render() {
        return this.wrapModal(<>

            <div className="modal fade" id="TemplateModal" tabindex="-1" aria-labelledby="TemplateModal" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">

                        <div className="modal-header">
                            <div className="text-end">
                                <CloseButton aria-label="Hide Modal" />
                            </div>
                            <h1 className="modal-title fs-5 float-start" id="TemplateModal">Available Templates</h1>
                            <span class="float-end">
                                <a href="/" aria-label="Close" data-dismiss="modal">
                                    <i className="far fa-times-circle pt-1 pb-1" style={{ fontSize: '20px', color: '#888' }} ></i>
                                </a>
                            </span>
                        </div>
                        <div className="modal-body" style={{ backgroundColor: '#eee' }} >

                            <div className="row ml-2 mr-2">

                                <div classNameName="col-lg-4 col-sm-6 col-12 p-4">
                                    <div className="card text-center">
                                        <div className="card-body">

                                            <img src="/img/web1.jpg" className="card-img-top img-fluid mb-3" style={{ borderRadius: '12px' }} alt="..." />

                                            <div className="">
                                                <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#website1">Quick View</a>
                                                <a href="/" className="btn btn-outline-primary">Select Site</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12 p-4">
                                    <div className="card text-center">
                                        <div className="card-body">

                                            <img src="/img/web2.jpg" className="card-img-top img-fluid mb-3" style={{ borderRadius: '12px' }} alt="..." />

                                            <div className="">
                                                <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#website2">Quick View</a>
                                                <a href="/" className="btn btn-outline-primary">Select Site</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12 p-4">
                                    <div className="card text-center">
                                        <div className="card-body">

                                            <img src="/img/web3.jpg" class="card-img-top img-fluid mb-3" style={{ borderRadius: '12px' }} alt="..." />

                                            <div className="">
                                                <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#website3">Quick View</a>
                                                <a href="/" className="btn btn-outline-primary">Select Site</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12 p-4">
                                    <div class="card text-center">
                                        <div class="card-body">

                                            <img src="/img/web4.jpg" className="card-img-top img-fluid mb-3" style={{ borderRadius: '12px' }} alt="..." />

                                            <div className="">
                                                <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#website4">Quick View</a>
                                                <a href="/" className="btn btn-outline-primary">Select Site</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12 p-4">
                                    <div className="card text-center">
                                        <div className="card-body">

                                            <img src="/img/web5.jpg" className="card-img-top img-fluid mb-3" style={{ borderRadius: '12px' }} alt="..." />

                                            <div className="">
                                                <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#website5">Quick View</a>
                                                <a href="/" className="btn btn-outline-primary">Select Site</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6 col-12 p-4">
                                    <div className="card text-center">
                                        <div className="card-body">

                                            <img src="/img/web6.jpg" className="card-img-top img-fluid mb-3" style={{ borderRadius: '12px' }} alt="..." />

                                            <div className="">
                                                <a href="/" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#website6">Quick View</a>
                                                <a href="/" className="btn btn-outline-primary">Select Site</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-secondary mb-2" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Use this Template</button>
                        </div>
                    </div>
                </div>
            </div>

        </>, null, 'xl');
    }
}