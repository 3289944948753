import BaseCom from './BaseCom';
import { Modal } from 'react-bootstrap';
import Icon from './Icon';
import Alert from './Alert';
import { qreq } from '../shared/qrequest';
import { EditorGlobal } from './Editor';
import FormInput from './FormInput';
import { v4 as uuidv4 } from 'uuid';

export const NavEditorGlobal = {
    ver: false
};

export default class NavEditorModal extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: null,
            pages: []
        };
        this.load = this.load.bind(this);
        this.addItem = this.addItem.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show && this.props.show) {
            this.load();
        }
    }

    load() {
        if (EditorGlobal.siteDomainID) {
            qreq.get('/api/nav/get?siteDomainID=' + EditorGlobal.siteDomainID, j => {
                if (j.item) {
                    j.item.items.sort((a, b) => a.orderIndex - b.orderIndex);
                    this.setState({ nav: j.item, list: j.item.items });
                }
            }, this.unkownErrorCallback);

            qreq.get('/api/editor/page/list?siteDomainID=' + EditorGlobal.siteDomainID, j => {
                if (j.list) {
                    this.setState({ pages: j.list });
                }
            });            
        }
    }

    addItem() {
        this.setState({ list: [...this.state.list, {}] });
    }

    updateItem(item, key, value) {
        item[key] = value;
        this.setState({ list: [...this.state.list] });
    }

    removeItem(index) {
        this.setState({ list: this.state.list.filter((item, i) => i !== index) });
    }

    save(e) {
        e?.preventDefault();
        var list = [...this.state.list];
        var idx = 0;
        list.forEach(i => {
            i.orderIndex = idx++;
            i.pageID = Number(i.pageID);
        });
        qreq.post('/api/nav/save', { ...this.state.nav, siteDomainID: EditorGlobal.siteDomainID, items: list }, j => {
            if (j.errorCode === 0) {
                this.props.onHide();
                this.setState({ list: null });
                NavEditorGlobal.ver = uuidv4();
                this.props.onSave(NavEditorGlobal.ver);
            }
            else
                this.alert(j.errorMessage);
        });
    }

    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Nav Editor</Modal.Title>
            </Modal.Header>
            <form onSubmit={this.save}>
                <Modal.Body>
                    {this.state.list ? <div className="mb-3">
                        <button type="button" className="btn btn-primary" onClick={this.addItem}><Icon icon="faPlus" /> Add Nav Item</button>
                    </div> : null}
                    <div className="list-group">
                        {this.state.list?.map((item, index) => <div key={index} className="list-group-item">
                            <div className="row">
                                <div className="col"><input type="text" className="form-control" value={item.name} onChange={e => this.updateItem(item, 'name', e?.target?.value)} placeholder="Nav Title" required /></div>
                                <div className="col">
                                    <FormInput model={item} name="pageID" label="Page" type="select" options={this.state.pages} onChange={v => this.updateItem(item, 'pageID', v)} labelAsPlaceholder required />
                                </div>
                                <div className="col-1 text-center"><button type="button" className="btn btn-danger" onClick={() => this.removeItem(index)}><Icon icon="faTrash" /></button></div>
                            </div>
                        </div>)}
                        {this.state.list?.length === 0 ? <Alert onClick={this.addItem}>Add your first nav item!</Alert> : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" className="btn btn-secondary" onClick={this.props.onHide}><Icon icon="faCancel" /> Cancel</button>
                </Modal.Footer>
            </form>
        </Modal>;
    }
}