import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import Icon from "./Icon";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AISurvey extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
    }

    submit() {
        this.setState({ submitting: true });
        var data = {
            name: this.state.info.name,
            subType: this.state.info.type,
            payload: JSON.stringify({
                name: this.state.info.name, type: this.state.info.type, description: this.state.info.description, templateID: this.props.tpl.id,
                address: this.state.info.address,
                phone: this.state.info.phone,
                products: [
                    this.state.info.product1,
                    this.state.info.product2,
                    this.state.info.product3
                ],
                addressCityStateOnly: this.state.info.addressCityStateOnly
            })
        }
        qreq.post('/api/ai/generate?siteDomainID=' + (this.props.params.templateID ?? '0'), data, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.setState({ processing: true });
                this.poll(j.item);
            }
            this.setState({ submitting: false });
        }, () => {
            this.alert('Unkown error ocurred. Please try again.');
            this.setState({ submitting: false });
        });
    }

    poll(task) {
        qreq.get('/api/ai/status?taskID=' + task.id, j => {
            if (j.errorCode) {
                this.alert(j.errorMessage);
            }
            else {
                if (j.item._StatusResult === 1) {
                    setTimeout(() => this.poll(task), 1000);
                }
                else if (j.item._StatusResult === 0) {
                    this.setState({ success: true, processing: false, indexID: Number(j.item.result) });
                    window.location.reload();
                    //this.props.navigate('/editor/page/' + Number(j.item.result));
                    //this.props.onHide();
                }
                else {
                    this.setState({ error: j.item.result, processing: false });
                    setTimeout(() => {
                        this.setState({ error: null });
                    }, 5000);
                }
            }
        }, () => {
            this.setState({ error: 'Unknown error. Please try again.', processing: false });
        });
    }

    render() {

        return <div className="container-fluid">
            <span className="float-end">
                <span onClick={this.props.onBack} role="button" className="fs-2 text-white" data-bs-dismiss="modal" aria-label="Close">
                    <Icon icon="faTimes" />
                </span>
            </span>
            <h1 className="text-center modal-header-weight mt-2 mb-3 text-shadow">
                Let AI Build your Website
            </h1>
            <div className="bg-light p-3 rounded">
                {this.state.success ? <Alert type="success">Completed!</Alert> : this.state.error ? <Alert type="danger">{this.state.error}</Alert> : this.state.processing ? <Alert type="warning">Processing please wait...</Alert> : this.state.submitting ? <LoadingAlert /> :
                    <form onSubmit={this.submit}>
                        <div className="row">
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="name" label="Business Name" placeholder="Bob's Burgers" required />
                            </div>
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="type" label="Industry" placeholder="Restaurant" required />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="address" label="Full Address" placeholder="Test St. 123 San Juan PR 00926" required />
                            </div>
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="phone" label="Business Phone #" placeholder="000 000 0000" required />
                            </div>
                        </div>
                        <FormInput type="text" model={this.state.info} name="product1" label="Product/Service #1" placeholder="My Product/Service 1" />
                        <FormInput type="text" model={this.state.info} name="product2" label="Product/Service #2" placeholder="My Product/Service 2" />
                        <FormInput type="text" model={this.state.info} name="product3" label="Product/Service #3" placeholder="My Product/Service 3" />
                        <FormInput type="textarea" model={this.state.info} name="description" label="Business Description" placeholder="A short description about your business..." />
                        <div className="mt-2 d-grid gap-2">
                            <button type="submit" className="btn btn-success btn-lg">Generate!</button>
                        </div>
                    </form>}
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(AISurvey);