import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AdminSitesEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            organizations: []
        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        if(this.props.params.id) {
            qreq.get('/api/admin/domain/get?id=' + this.props.params.id, j => {
                if(j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }

        qreq.get('/api/admin/organization/list', j => {
            if(j.list)
                this.setState({ organizations: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    submit(e) {
        if(e) e.preventDefault();
        qreq.post('/api/admin/domain/save', {...this.state.info}, j => {
            if(j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Site properties saved!');
                this.props.navigate('/admin/sites');
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <AdminLayout>
            <div className="container-fluid">
                <form onSubmit={this.submit}>
                    <Card title="Site Info">
                        <FormInput model={this.state.info} name="domainName" label="Domain" type="text" />
                        <FormInput model={this.state.info} name="organizationID" label="Organization" type="select" options={this.state.organizations} />
                        <FormInput model={this.state.info} name="resyncDNS" label="Resync DNS" type="switch" />
                        <FormInput model={this.state.info} name="renewCert" label="Re-trigger Renew Certificate" type="switch" />
                    </Card>
                    <div className="app-form-buttons">
                        <button onClick={this.goBack} type="button" className="btn btn-secondary"><Icon icon="faCancel" /> Cancel</button>
                        <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    </div>
                </form>
            </div>
        </AdminLayout>
    }
}

export default withParamsAndNavigate(AdminSitesEdit);