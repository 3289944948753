import { Link } from "react-router-dom";
import Auth from "../shared/Auth";
import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AdminHome extends BaseCom {
    componentDidMount() {
        Auth.getLogin(u => {
            if(u) {
                qreq.get('/api/admin/stats/sites', j => {
                    if(j.item)
                        this.setState({ stats: j.item });
                    else
                        this.alert(j.errorMessage);
                }, this.unkownErrorCallback);
            }
        });
    }

    render() {
        return <AdminLayout>
            <Card title="Websites">
                {this.state.stats ? <div className="row g-3">
                    <div className="col-6 col-lg-4 col-xl-3">
                        <div className=" text-center rounded border p-5">
                            <h5>Total</h5>
                            <div className="fs-1">
                                <Link to="/admin/sites">{this.state.stats.total}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4 col-xl-3">
                        <div className=" text-center rounded border p-5">
                            <h5>Live</h5>
                            <div className="fs-1">{this.state.stats.live}</div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4 col-xl-3">
                        <div className=" text-center rounded border p-5">
                            <h5>Delinquent</h5>
                            <div className="fs-1">{this.state.stats.delinquent}</div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-4 col-xl-3">
                        <div className=" text-center rounded border p-5">
                            <h5>Archived</h5>
                            <div className="fs-1">{this.state.stats.archived}</div>
                        </div>
                    </div>
                </div> : <LoadingAlert />}
            </Card>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminHome);