import Auth from "../shared/Auth";
import BaseCom from "./BaseCom";
import { Pop } from "./PopModal";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link, NavLink } from "react-router-dom";
import './AdminLayout.css';
import Icon from "./Icon";
import { Dropdown } from "react-bootstrap";
import LoadingAlert from "./LoadingAlert";

class AdminLayout extends BaseCom {
    componentDidMount() {
        Auth.getLogin(u => {
            if (!u || !Auth.isAdmin())
                this.props.navigate('/login');
            else {
                this.setState({ user: u });
            }
        });
    }

    render() {
        if(!this.state.user)
            return <LoadingAlert />
        return this.props.children;
    }
}

export default withParamsAndNavigate(AdminLayout);