import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import Icon from "./Icon";
import { Pop } from "./PopModal";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AdminTemplatesEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        if (this.props.params.id) {
            qreq.get('/api/template/get/' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
    }

    delete() {
        Pop.showConfirm('Are you sure you want to delete this template?', 'Delete', () => {
            qreq.post('/api/template/admin/delete', {...this.state.info}, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Template deleted!');
                    this.props.navigate('/admin/templates');
                }
            }, this.unkownErrorCallback);
        });
    }

    submit(e) {
        e?.preventDefault();
        qreq.post('/api/template/admin/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Template properties saved!');
                this.props.navigate('/admin/templates');
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <AdminLayout>
            <div className="container">
                <form onSubmit={this.submit}>
                    <Card title="Template" headerButtons={<button type="button" className="btn btn-danger" onClick={this.delete}><Icon icon="faTrash" /></button>}>
                        <FormInput model={this.state.info} name="templateName" label="Template Name" type="text" />
                    </Card>

                    <div className="app-form-buttons">
                        <button onClick={this.goBack} type="button" className="btn btn-secondary"><Icon icon="faCancel" /> Cancel</button>
                        <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    </div>
                </form>
            </div>
        </AdminLayout>
    }
}

export default withParamsAndNavigate(AdminTemplatesEdit);