import BaseCom from "./BaseCom";
import MonacoEditor from "@monaco-editor/react";

class CodeEditor extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <MonacoEditor
        height="80vh"
        language="css"
        theme="vs-dark"
        value={this.props.code}
        options={{
          inlineSuggest: true,
          fontSize: "16px",
          formatOnType: true,
          autoClosingBrackets: true,
          minimap: { scale: 10 }
        }}
        onChange={this.props.onChange}
      />;
    }
}

export default CodeEditor;