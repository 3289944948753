import BaseCom from "./BaseCom";
import Card from "./Card";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import FormInput from "./FormInput";
import { Link } from "react-router-dom";

class StartPage extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
    }

    render() {
        return <div className="container"><Card>
            <h1>We Build Great Looking Websites</h1>
            <h2 className="sub">When it comes to your digital presence, your website is your online storefront. And although that sounds like a term reserved for eCommerce, all businesses can benefit from opening one.</h2>
            <div className="row">
                <div className="col-sm-6">
                    <img src="/img/startpage/preview.png" alt="" className="img-fluid" />
                </div>
                <div className="col-sm-6">
                    <div className="bg-light-alt p-4 rounded drop-shadow">
                        <div className="bg-info-light p-3 rounded drop-shadow">
                            <h3 className="mb-3">Enter Your Website Domain:</h3>
                            <FormInput model={this.state.item} name="domain" label="mybusinessdomain.com" type="text" required labelAsPlaceholder />
                            <div className="d-flex justify-content-center mt-3">
                                <button type="submit" className="btn btn-success btn-lg drop-shadow">See Site Grade</button>
                            </div>
                        </div>
                        <div className="bg-warning-light p-3 rounded drop-shadow mt-3 d-flex justify-content-between align-items-center">
                            <h2 className="bolder mb-0">Don't Have A Site?</h2>
                            <Link to="/plan" className="btn btn-success btn-lg drop-shadow">Start Building</Link>
                        </div>
                        <h3 className="color-primary bolder mt-3">What Do You Get With A TAPLocal Site?</h3>
                        <ul>
                            <li>Domain and hosting</li>
                            <li>Ongoing maintenance</li>
                            <li>Unlimited updates</li>
                            <li>Acces to a professional design team</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Card></div>
    }
}

export default withParamsAndNavigate(StartPage);