import { Modal } from "react-bootstrap";
import BaseCom from "./BaseCom"
import FormInput from "./FormInput";
import { qreq } from "../shared/qrequest";
import Alert from "./Alert";

export const AIModalGlobal = {
    init(showFunction, hideFunction) {
        this.show = showFunction;
        this.hide = hideFunction;
    },

    show(html, onSave, quick) {
        //overriden on init
    },

    hide() {
        //overriden on init
    }
};

export default class AIModal extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            info: {
                len: 10,
                lenType: 'word'
            }
        };
        this.defaultInfo = { ...this.state.info };
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.submit = this.submit.bind(this);
        AIModalGlobal.init(this.show, this.hide);
    }

    show(html, onSave, quick) {
        this.setState({ value: html, showModal: true, onSave: onSave, quick: quick });
    }

    hide() {
        this.setState({ value: '', showModal: false, onSave: null, quick: null });
    }

    submit(e) {
        e.preventDefault();
        var info = { ...this.state.info };
        if (this.state.quick) {
            this.setState({ submitting: true });
            qreq.post('/api/ai/quick', info, j => {
                if (j.item) {
                    this.state.onSave(j.item);
                    this.setState({ showModal: false, info: this.defaultInfo });
                }
                else
                    this.alert(j.errorMessage);
                this.setState({ submitting: false });
            }, () => {
                this.unkownErrorCallback();
                this.setState({ submitting: false });
            });
        }
        else {
            this.state.onSave('[' + info.prompt.replace(/\|/g, '') + '|' + info.len + '|' + info.lenType + ']');
            this.setState({ showModal: false, info: this.defaultInfo });
        }
    }

    render() {
        return <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
            <Modal.Header closeButton>
                <Modal.Title>AI</Modal.Title>
            </Modal.Header>
            <form onSubmit={this.submit}>
                <Modal.Body>
                    <FormInput model={this.state.info} name="prompt" label="Write content about..." type="text" placeholder="About Us" />
                    <div className="row">
                        <div className="col">
                            <FormInput model={this.state.info} name="len" label="Length" type="number" step="1" />
                        </div>
                        <div className="col">
                            <FormInput model={this.state.info} name="lenType" label="Type" type="select" options={[
                                { id: 'word', name: 'Word(s)' },
                                { id: 'sentence', name: 'Sentence(s)' },
                                { id: 'paragraph', name: 'Paragraph(s)' },
                                { id: 'list', name: 'List Items' },
                            ]} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.submitting ? <Alert>Generating...Please wait...</Alert> : <>
                        <button type="submit" className="btn btn-success btn-lg" disabled={this.state.submitting}>
                            Insert
                        </button>
                        <button type="button" className="btn btn-secondary btn-lg" disabled={this.state.submitting} onClick={() => this.setState({ showModal: false })}>
                            Cancel
                        </button>
                    </>}
                </Modal.Footer>
            </form>
        </Modal>;
    }
}