import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default class ConfirmDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            body: props.message,
            showModal: props.show
        };
        this.onConfirm = props.onConfirm;
        this.onCancel = props.onCancel;
        this.onHide = props.onHide;
        this.confirm = this.confirm.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (prevProps.title !== this.props.title)
            this.setState({ title: this.props.title });
        if (prevProps.message !== this.props.message)
            this.setState({ body: this.props.message });
        if (prevProps.show !== this.props.show)
            this.setState({ showModal: this.props.show });
        if (prevProps.onConfirm !== this.props.onConfirm)
            this.onConfirm = this.props.onConfirm;
        if (prevProps.onCancel !== this.props.onCancel)
            this.onCancel = this.props.onCancel;
        if (prevProps.onHide !== this.props.onHide)
            this.onHide = this.props.onHide;
    }

    confirm() {
        this.setState({ showModal: false });
        if (this.onConfirm)
            this.onConfirm();
    }

    cancel() {
        this.setState({ showModal: false });
        if (this.onCancel)
            this.onCancel();
    }

    render() {
        return <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }); if (this.onHide) this.onHide(); }}>
            <Modal.Header closeButton>
                <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => this.confirm()}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>;
    }
}