import BaseCom from "./BaseCom";
import Icon from "./Icon";

class ComPreview extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            com: props.com
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.com !== prevProps.com) {
            var com = { ...this.props.com };
            if (com.element === 'li' || com.element === 'img')
                com.element = 'div';
            this.setState({ com: com });
        }
    }

    render() {
        return <div className="text-center">
            {this.props.com.editor === 'menu' ? <>
                <nav className="navbar navbar-light bg-light">
                    <div class="container-fluid">
                        <a className="navbar-brand">Navbar</a>
                    </div>
                </nav>
            </> :
                <this.state.com.element className={this.props.com.className}>
                    {this.props.com.innerElement === 'img' ? <div className="fs-1"><Icon icon="faImage" /></div> : ''}
                    {this.props.com.name}
                </this.state.com.element>}
        </div>;
    }
}

export default ComPreview;