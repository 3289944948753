import BaseCom from "./BaseCom";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import NavDropdown from 'react-bootstrap/NavDropdown';

class Topbar extends BaseCom {
    render() {
        return <nav className="c-topbar navbar navbar-light">
            <div className="container-fluid d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                    <img src="/img/favicon.svg" alt="" style={{ maxHeight: '60px' }} />
                    <h1>Web Creator</h1>
                </div>
                <div className="d-flex justify-content-end align-items-center pe-5">
                    <NavDropdown
                        id="nav-dropdown"
                        title={<>Hi <strong>User Here</strong> <span className="c-usericon">U</span></>}
                        menuVariant="light"
                        className="c-user">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                            Separated link
                        </NavDropdown.Item>
                    </NavDropdown>
                </div>
            </div>
        </nav>
    }
}

export default withParamsAndNavigate(Topbar);